<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.tourSect.initBtn') }}
      </div>
      <section class="modal--container choose--date-container">
        <date-picker
          :inline="true"
          :disabledDates="disabledDates"
          :minDate="new Date()"
        ></date-picker>
        <hr class="separator" />
        <div class="btn btn-primary main--btn" @click="close">
          {{ $t('general.selectDate') }}
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const DatePicker = () => import('@/components/utils/date-picker');
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    disabledDates: [new Date('2021-05-30')],
    modalName: 'modal-choose-date',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
